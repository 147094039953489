<template>
  <v-container class="general list">
    <page-title
      :component="'QuestionWizard'"
      :translatable-tooltip="$t('question_list_page.page_title_tooltip')"
    >
      <template v-slot:actions>
        <LanguageSwitcher :loading="isLoading" />
        <VideoTutorial
          :component="'QuestionWizard'"
          :title="$t('questions-tutorial-title')"
          class="ml-4"
        />
      </template>
    </page-title>

    <transition name="fade" mode="out-in">
      <v-layout
        v-if="!isLoading && !plugins.length"
        class="row wrap text-center"
      >
        <v-flex class="xs12 sw-h5 font-weight-light">
          {{ $t("question_list_page.nothing_created") }}
        </v-flex>
      </v-layout>
    </transition>

    <template v-if="!isLoading && plugins && plugins.length">
      <v-layout
        v-for="(plugin, i) in plugins"
        :key="`plugin-${i}`"
        :style="{ width: '100%' }"
        class="row wrap align-center px-4"
      >
        <v-flex class="xs12">
          <v-layout class="row align-center">
            <v-flex class="xs12 py-4">
              <a
                class="sw-h5 font-weight-light"
                @click.stop="openWizard(plugin.id)"
                >{{ plugin.name }}</a
              >
            </v-flex>
            <v-btn
              icon
              class="ma-0"
              :isLoading="isLoading"
              @click="deletePlugin(plugin)"
            >
              <font-awesome-icon icon="trash" class="sw-accent" />
            </v-btn>
          </v-layout>
        </v-flex>
        <v-flex class="xs12">
          <v-divider></v-divider>
        </v-flex>
      </v-layout>
    </template>

    <div v-if="totalPages > 1" class="text-center pt-4">
      <v-pagination
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="listPlugins()"
      ></v-pagination>
    </div>

    <AppSpeedDial
      @click="$router.push({ name: 'questions_create' })"
      :hint="$t('question_list_page.speed_dial_hint')"
    />
    <ConfirmationDialog ref="confirmationDialog" />
  </v-container>
</template>

<script>
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";

export default {
  data: () => ({
    isLoading: false,
    plugins: [],
    page: 1,
    perPage: 16,
    totalPages: null,
    totalVisible: 5,
  }),
  computed: {
    groupId() {
      return this.$route.params.group_id;
    },
    currentPage() {
      if (this.$route.query.page) {
        return Number(this.$route.query.page);
      }
      return 1;
    },
    appContentLanguage() {
      return this.$store.getters.appContentLanguage;
    },
  },
  components: {
    LanguageSwitcher,
  },
  mounted() {
    this.$nextTick(() => {
      this.page = this.currentPage;
      this.listPlugins();
    });
  },
  methods: {
    query(page) {
      this.$router
        .replace({
          query: Object.assign({}, this.$route.query, { page: page }),
        })
        .catch(() => {});
    },
    openWizard(id) {
      if (!id) return;

      this.$router
        .push({
          name: "questions_edit",
          params: {
            group_plugin_id: id,
          },
        })
        .catch(() => {});
    },
    async listPlugins() {
      try {
        const params = [
          this.groupId,
          {
            prefix: "questionwizard",
            with_attributes: 1,
            page: this.page,
            per_page: this.perPage,
            lang: this.appContentLanguage,
          },
        ];

        this.isLoading = true;

        const response = await this.$api.groupPlugins.list(...params);

        this.isLoading = false;

        this.plugins = response.data.data;
        this.totalPages = response.data.pagination.total_pages;
        this.query(this.page);
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
    async deletePlugin(model) {
      if (!model) return;

      try {
        const isConfirmed = await this.$refs.confirmationDialog.open(
          this.$t("questionWizardDeleteConfirmationTextLine", {
            name: `${model.name}`,
          }),
          this.$t("common.no"),
          this.$t("common.yes"),
        );

        if (!isConfirmed) return;

        const params = [this.groupId, model.id];

        this.isLoading = true;

        await this.$api.groupPlugins.delete(...params);

        this.isLoading = false;

        this.$store.dispatch("addNotification", {
          message: this.$t("questionWizardDeleteSuccessNotification", {
            name: model.name,
          }),
        });

        this.listPlugins();
      } catch (error) {
        this.isLoading = false;

        if (error) {
          this.errorMessageShow(error);
        }
      }
    },
  },
  watch: {
    appContentLanguage: {
      handler() {
        this.listPlugins();
      },
    },
  },
};
</script>
